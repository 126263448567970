import React, { useState, useRef, useEffect } from "react"
import { css } from "@emotion/react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import ContentContainer from "../../components/contentcontainer"
import SuccessMessage from "../../components/successmessage"
import { Row, Col, Form, Button, Spinner, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css" // muss für den Datepicker eingebunden werden
import de from 'date-fns/locale/de' // das ist für die Lokalisierung der Wochentage im Datepicker
import * as dateHelpers from '../../utils/datehelpers'
import SEO from "../../components/seo"

// #################################################
// ###### Globale Definitionen und Funktionen ######
// #################################################

// Lokalisierung der Wochentage im Datepicker
registerLocale('de', de)

// ###############################
// ###### CheckAvailability ######
// ###############################

const CheckAvailability = (props) => {

  const [currentSelectedDate, setCurrentSelectedDate] = useState(new Date());
  const [showSpinner, setShowSpinner] = useState(false);
  // um den DatePicker auch von aussen zu öffnen (nämlich von unserem Calender-Icon)
  // muss man etwas Krude mit einer ref arbeiten (ist eine Referenz auf die Komponente)
  // in der Hook-Variante wird dazu useRef verwendet
  // im DatePicker selbst wird dann die ref auf die hier definierte ref übergeben
  const datePickerRef = useRef(null);

  const handleBtnNextClick = () => {
    //setze showSpinner auf True, damit der Spinner angezeigt wird
    setShowSpinner(true)
    // nach einer gewissen Fake-Zeit zum nächsten Schritt gehen
    setTimeout(function () {
      // Die über die Props übergebene Callback-Funktion ausführen
      props.onNextStep(currentSelectedDate)
    }, 3000);
  }

  const handleBtnPrevClick = () => {
    props.onPrevStep()
  }

  // Hilfsfunktion, die alle Tage bis auf Sonntage zurückgibt
  // Die Filterfunktion wird für den DatePicker verwendet
  const isDayWithoutSunday = date => {
    const day = date.getDay();
    // return day !== 0 && day !== 6; // Samstage und Sonntage aussortieren
    return day !== 0;
  };

  // der useEffect setzt das readOnly Attribut auf true, damit sich auf mobile kein Screen Keyboard öffnet
  // das ist ein Workaround (aber leider best Practice)
  // Eigentlich würde man es direkt in der Prop über readOnly={true} definieren
  // aber dann würde sich der Kalender nicht mehr öffnen (weil der onClick handler außer Betrieb wäre)
  // also bleibt nur die Wahl, das über useEffect bei ComponentMont zu machen
  useEffect(() => {
    if (datePickerRef.current !== null) {
      datePickerRef.current.input.readOnly = true;
    }
  }, [datePickerRef]);

  return (
    <div className="contactUserFlowContainer text-center">
      <h4 className="mb-3">Wunschtermin verfügbar?</h4>
      <p>Gib unten deinen Wunschtermin für das Shooting ein und wir prüfen, ob der Termin noch verfügbar ist.</p>
      <Form>
        <Form.Group controlId="ReservationForm.preferredDate" className="mt-4">
          <InputGroup className="d-flex justify-content-center">

            <InputGroup.Prepend>
              {/* damit man ausserhalb auf das Kalendersymbol klicken kann und sich der Datepicker öffnet, müssen wir etwas Krude mit einer Ref arbeiten
              Dazu wird zuerst eine mit einem Hook useRef die datePickerRef definiert und dann eine "innere-Methode" der Referenz aufgerufen (setOpen); das current ist Teil der useRef-API */}
              <InputGroup.Text onClick={(e) => datePickerRef.current.setOpen(true)}><FontAwesomeIcon icon={faCalendar} size="1x" /></InputGroup.Text>
            </InputGroup.Prepend>
            {/* mit filterDate werden hier die Sonntage herausgefiltert
              damit mobile kein ScreenKeyboard eingeblendet wird, muss die readOnly Eigenschaft gesetzt werden
              sobald das CustomInput des DatePicker einen Fokus erhält; dafür benötigen wir einen State datePickerReadOnly
              background-color muss hier gesetzt werden, da readOnly sonst ausgegrautes Feld ist
              popperPlacement & popperModifiers -> damit der DatePicker *immer* unter dem Textfeld aufgeht
              Sonst ist springt das Feld in mobile
            */}
            <DatePicker
              selected={currentSelectedDate}
              onChange={date => setCurrentSelectedDate(date)}
              locale="de"
              dateFormat="dd.MM.yyyy"
              customInput={<Form.Control type="text" name="preferreddate" placeholder="date" />}
              filterDate={isDayWithoutSunday}
              className="text-center"
              ref={datePickerRef}
              css={css`&[readonly] {background-color: white;}`}
            />

          </InputGroup>
        </Form.Group>
      </Form>
      <Row>
        <Col>
          <Button onClick={handleBtnPrevClick} variant="primary" block className="mb-5 mt-4">Zurück</Button>
        </Col>
        <Col>
          <Button onClick={handleBtnNextClick} variant="primary" block className="mb-5 mt-4">
            {showSpinner ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" /> : null}
            Termin prüfen
          </Button>
        </Col>
      </Row>
    </div>
  )
}

// ###############################
// ##### AvailabilitySuccess #####
// ###############################

const AvailabilitySuccess = (props) => {

  return (
    <div className="contactUserFlowContainer text-center">
      <SuccessMessage>Der Termin {props.selectedDate} ist verfügbar!</SuccessMessage>
      <p>Glückwunsch dein Wunschtermin ist noch verfügbar! Möchtest du den Termin unverbindlich reservieren?</p>
      <Row className="mb-4 mt-4">
        <Col>
          <Button onClick={props.onPrevStep} variant="primary" block>Zurück</Button>
        </Col>
        <Col>
          <Button onClick={props.onNextStep} variant="primary" block>Reservieren</Button>
        </Col>
      </Row>
    </div>
  )
}

// ###############################
// ######### Reservation #########
// ###############################

const ReservationForm = (props) => {

  const [showSpinner, setShowSpinner] = useState(false);
  // VALIDIERUNG DER FORMFELDER
  // man kann mit noValidate in der Form verhindern, dass der Browser eine Standardvalidierung macht (die nicht schön aussieht)
  // dann müssen wir uns aber selbst um die Validierung kümmern (dafür ist die Funktion unten)
  // VORHER: bevor ich das eingebaut habe wurde bei Submit direkt die Funktion props.handleSendClick ausgeführt
  // das lokale handleSubmit wurde hier als Zwischenebene eingezogen
  const [validated, setValidated] = useState(false);
  const handleBtnMakeReservationClick = (event) => {
    const form = event.currentTarget
    // zuerst wird mit checkValidity geprüft ob alle Checks okay sind (required Felder auch ausgefüllt sind)
    if (form.checkValidity() === false) {
      // fall nein, setze validated auf true und zeige damit die Validierungs-UI an (Felder grün und rot markiert)
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      // falls die Validierung okay war, führe die Funktion aus, die über die Callbacks übergeben wurde
      // leite hier das event durch, damit von außen die Inhalte der Form ausgelesen werden können
      sendFormData(event)
    }
  }

  const sendFormData = (e) => {
    // die normale Weiterleitung verhindern
    e.preventDefault()
    //setze showSpinner auf True, damit der Spinner angezeigt wird
    setShowSpinner(true)

    // Definiere neues Objekt formData mit allen Daten des Formulars
    const formData = new FormData(e.target)
    // ergänze ein weiteres Feld in den formData (damit wir in der E-Mail später wissen, wo die Daten herkommen)
    formData.append("subject", "Reservierung")
    // Fülle ein formDataObj mit allen Einträgen aus dem Formular
    const formDataObj = Object.fromEntries(formData.entries())
    // DEBUG
    // console.log(formData)
    // console.log(formDataObj)
    // console.log(JSON.stringify(formDataObj))


    // verwende die API, um Daten per POST dorthin zu senden
    // Vorsicht: als Body darf dort weder formData stehen (ist leer)
    // noch formDataObj -> das ist ein Object, wir brauchen aber JSON-Daten
    // also das Objekt noch in JSON stringifien
    fetch(`${process.env.CONTACTMAIL_API_URL}`, {
      method: 'POST',
      body: JSON.stringify(formDataObj),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then((data) => {
        // Success!
        // console.log('Success:', data); // DEBUG
        // Die über die Props übergebene Callback-Funktion ausführen
        props.onNextStep()
      })
      .catch((error) => {
        // Error!
        // console.error('Error:', error); // DEBUG
        navigate('/404/')
      });
  }

  return (
    // Das noValidate ist notwendig, um das Standard-Browser-Verhalten zur Formvalidierung zu unterbinden
    <div className="contactUserFlowContainer">
      <Form css={css`label {font-size: 80%; text-transform: uppercase; font-weight: bold;}`} onSubmit={handleBtnMakeReservationClick} noValidate validated={validated}>
        <Form.Row>
          <Col>
            <Form.Group controlId="reservationForm.firstname">
              <Form.Label>Vorname</Form.Label>
              <Form.Control type="text" name="firstname" placeholder="Vorname" required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="reservationForm.lastname">
              <Form.Label>Nachname</Form.Label>
              <Form.Control type="text" name="lastname" placeholder="Nachname" required />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Group controlId="reservationForm.email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="email" placeholder="name@example.com" required />
        </Form.Group>
        <Form.Group controlId="reservationForm.phone">
          <Form.Label>Telefon</Form.Label>
          {/* pattern validiert die Telefonnummer; erlaubt sind Digits, Whitespace () und -; min 10 Stellen, max 17 */}
          <Form.Control type="tel" pattern='^\+?[\d ()-]{10,17}' name="phone" placeholder="+49 151 1234567" required />
        </Form.Group>
        <Form.Group controlId="reservationForm.ControlSelectService">
          <Form.Label>Art des Shootings</Form.Label>
          <Form.Control as="select" name="topic">
            <option>Babybauch</option>
            <option>Newborn</option>
            <option>Baby</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="reservationForm.preferredDate">
          <Form.Label>Termin</Form.Label>
          <InputGroup className="d-flex justify-content-center">
            <InputGroup.Prepend>
              <InputGroup.Text><FontAwesomeIcon icon={faCalendar} size="1x" /></InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="text" name="reservationdate" defaultValue={props.reservationDate} readOnly />
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="reservationForm.text">
          <Form.Label>Zusätzliche Details</Form.Label>
          <Form.Control as="textarea" rows={3} name="details" />
        </Form.Group>
        <Form.Group controlId="reservationForm.hp" className="d-none">
          <Form.Control type="text" name="hp" />
        </Form.Group>
        <Row className="mt-2">
          <Col>
            <small className="text-muted text-center">Die Reservierung ist selbstverständlich unverbindlich und du gehst damit keinerlei Verpflichtungen ein.</small>
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <Col>
            <Button onClick={props.onPrevStep} variant="primary" block>Zurück</Button>
          </Col>
          <Col>
            <Button type="submit" variant="primary" block>
              {showSpinner ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" /> : null}
              Reservieren
            </Button>
          </Col>
        </Row>


      </Form>
    </div>
  )
}

// ###############################
// ###### ReservationSuccess #####
// ###############################

const ReservationSuccess = (props) => {
  return (
    <div className="contactUserFlowContainer text-center">
      <SuccessMessage>Wir haben deine Daten erhalten und kümmern uns baldmöglichst um dein Anliegen</SuccessMessage>
    </div>
  )
}



// ###############################
// ######## Main Function ########
// ###############################

export default function Reservation() {
  const [step, setStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState();

  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  const onBtnCheckAvailability = (selectedDateInChild) => {
    //console.log(selectedDateInChild)
    setSelectedDate(selectedDateInChild)
    nextStep()
  }


  return (
    <Layout>
      <SEO
        title="Wunschtermin | Babyfotografin Köln"
        description="Hier kannst du direkt den besten Termin für dein Shooting bestimmen und deinen Wunschtermin reservieren. Oder du schreibst mir einfach und wir besprechen alles weitere."
      />
      <ContentContainer>
        {step === 0 && <CheckAvailability onNextStep={(selectedDateInChild) => onBtnCheckAvailability(selectedDateInChild)} onPrevStep={() => navigate('/contact/')}></CheckAvailability>}
        {step === 1 && <AvailabilitySuccess onNextStep={nextStep} onPrevStep={prevStep} selectedDate={dateHelpers.formatDateToString(selectedDate)}></AvailabilitySuccess>}
        {step === 2 && <ReservationForm onNextStep={nextStep} onPrevStep={prevStep} reservationDate={dateHelpers.formatDateToString(selectedDate)}></ReservationForm>}
        {step === 3 && <ReservationSuccess onNextStep={nextStep} onPrevStep={prevStep}></ReservationSuccess>}
      </ContentContainer>
    </Layout>
  )

}
